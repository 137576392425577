/* @tailwind base; */
/* @tailwind components; */
/* @tailwind utilities; */

.header {
  text-align: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left!important;
}

.text-right {
  text-align: right!important;
}

.text-clipped {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.all-height {
  height: 100%;
}

.flex{
  display: flex;
}

.flex-center {
  display:flex;
  justify-content: center;
  align-items: center;
}

.flex-center-end {
  display:flex;
  align-items: center;
  justify-content: end;
}

.flex-nowrap-justify-around{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.flex-nowrap-justify-between{
  display:flex;
  flex-wrap: nowrap;
  justify-content: space-between!important;
  align-items: center;
}

.flex-nowrap-justify-evenly{
  display:flex;
  flex-wrap: nowrap;
  justify-content: space-evenly!important;
  align-items: center;
}

.flex-col-center {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.m-w-500 {
  min-width: 500px;
}

.w-30px {
  width: 30px!important;
}

.w-160px {
  width: 160px!important;
}

.w-384px {
  width: 384px!important;
}

.items-center{
  align-items: center;
  justify-content: center;
}

.round-border{
  border-radius: 15px;
}

.logo-opacity {
  opacity:0.3;
}

.page-container{
  display: flex;
  flex-direction: column;
  height: 100vh !important;
}

.in-page-flex {
  display: flex;
  flex-direction: column;
  height:100%
}

.content-wrap{
  flex-grow:1!important;
}

.the-background {
  background: linear-gradient(#467380,#006b6c, #004a55, #004a55);
  background-size: cover;
}

.background-footer {
  background-color: #467380;
}

.white-background {
  background-color: white;
}

.main-logo {
  display:flex;
  justify-content: space-around;
  padding-bottom: 10px;
}

.main-logo-img {
  width:100%;
  max-width:410px;
  max-height:151px;
}

.image-mentions {
  width:100%;
  height:100%;
  max-width: 330px;
}

.stay-in-div {
    display:table-cell;
    width:100%;
}

.login-logo {
  color: #607D8B;
}

.squama-font {
  font-family: 'Source Sans Pro';
}

.error-font {
  font-size : 30px;
  color:red;
}

.red-font {
  color:red;
}

.black-font {
  color: black!important;
}

.grey-font {
  color: #9E9E9E!important;
}

.white-font {
  color: white;
}

.warning-font {
  color:orange;
}

.alarm-font {
  color:red;
}

.h1-font {
  font-size: 2.5rem;
  text-align: center;
}

.h2-font {
  font-size: 1rem;
}

.border-1px {
  border: 1px solid #9E9E9E;
}

.border-solid {
  border-style: solid !important;
  border-radius: 0.15rem !important;
  --tw-border-opacity: 1 !important;
  border: 1px solid #9E9E9E;
}

.border-solid-red {
  border-style: solid !important;
  border-radius: 0.15rem !important;
  --tw-border-opacity: 1 !important;
  border-color: #F66A60!important;
}

.border-solid-orange {
  border-style: solid !important;
  border-radius: 0.15rem !important;
  --tw-border-opacity: 1 !important;
  border-color: #F6B960!important;
}

.btn-station-card {
  height: 35px;
  min-width: 120px;
  color: #9E9E9E;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: normal;
  font-style: bold;
  text-decoration: none;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #9E9E9E;
  border-radius: 10px;
}

.btn-station-card-spacer {
  width: 16px!important;
}

.btn-selected {
  background: #607D8B!important;
  color: #FFFFFF!important;
}

.btn-login {
  color: #607D8B;
  font-family: 'Source Sans Pro';
  font-size: 20px;
}

.login-part {
  max-width: 400px;
}

.login-stay-connected {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.btn-navbar:active {
  background: #D9E6EC;
}

.hruler-navbar {
  height: 27px;
  border: 5px solid #607D8B;
}

.conditionnal-button-nabar {
  padding-top: 0.25rem /* 4px */ !important;
  padding-bottom: 0.25rem /* 4px */ !important;
}

@media screen and (min-width: 801px) {
  .row-navbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
  }
}

@media screen and (max-width: 800px) {
  .row-navbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* row-gap: 16px; */
  }
}

.squama-item-navbar-conditionnal {
  padding-right: 1.25rem /* 20px */ !important;
}

.squama-item-navbar {
  width: 280px;
  text-align: center;
  display:flex;
}

.squama-select {
  width: 260px;
  color: #607D8B;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background: #caddd5;
  border: 1px solid #BDBDBD;
  border-radius: 26px;
}

.squama-item-navbar-companies {
  color: #608b6d!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.squama-btn-navbar-companies {
  width: 260px;
  height:34px;
  color: #607D8B;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background: #D9E6EC;
  border: 1px solid #BDBDBD;
  border-radius: 26px;
}

.squama-btn-navbar:active {
  font-weight: bold;
  background: #D9E6EC;
}

.squama-btn-navbar {
  width: 260px;
  color: #607D8B;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 26px;
}

.squama-btn-navbar:disabled {
  background: #607d8b!important;
  color: #607d8b!important;
}

.squama-btn-gestion {
  background: #607d8b5b!important;
  color: white!important;
}

.squama-btn-gestion-delete {
  background: #b31e1e5b!important;
}

.squama-btn-gestion-modal {
  width: 130px!important;
}

.squama-btn-gestion-modal-bigger {
  width: 225px!important;
}

.squama-btn-gestion-one-element {
  width: 33px!important;
}

.flex-station-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flex-station-cards-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 600px;
}

.sensor-name-td {
  text-align: right;
  min-width: 25px;
}

.station-card-body-table {
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sensor-flex-inside-card {
  /*flex:1 0 50%;*/
  flex-basis: 48%;
  width: 230px;
}

.sensor-table-td-for-alignement-sensors-name {
  width:23px
}

.sensor-table-td-for-alignement-sensors-logo {
  width:63px
}

.sensor-table-td-for-alignement {
  width: 10%;
}

.panel-station-default {
  flex: 1 0 31%; /* 3 items per row max */
  margin: 15px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 15px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #607D8B;
  display:flex;
  flex-direction: column;
  min-width: 305px;/*Needed for mobile station width ?!*/
  min-height: 330px;
}

.panel-station-smaller {
  min-height: 150px!important;
}

.panel-station-large-enough {
  min-width: 570px!important;
}

.panel-station-default-not-fullscreen {
  max-width: 572px;
}

.panel-station-default-setup-chart {
  min-height: 572px!important;
  min-width: 572px!important;
}

.panel-station-default-setup-chart-center-text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 572px!important;
}

.panel-station-default-mapmode {
  width: 570px;
  min-height: 330px;
}

.panel-station-default-fullscreen {
  min-width: 300px;
  min-height: 330px;
}

.panel-station-heading {
  color: #616161;
  font-family: 'Source Sans Pro';
  font-size: 26px;
  font-weight: bold;
  height: 40px;
  border-top-right-radius: 10px;
  border-top-left-radius: 15px;
}
.panel-station-thermeau-heading {
  background: #E0E0E0;
}
.panel-station-flow-heading {
  background: #BACA95;
}
.panel-station-assec-heading {
  background: #E0E0E0;
}
.panel-station-pap-heading {
  background: #9BC1BE;
}
.panel-station-papang-heading {
  background: #B8B7C9;
}
.panel-station-flhauteur-heading {
  background: #BFE6F7;
}
.panel-station-parameters-heading {
  background: #CFCFCF;
  padding-left: 30px;
}

.panel-station-body {
  color: #616161;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  padding:5px;
  flex:1;
}

.panel-station-migration_in_progress-status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-station-footer {
  background: none;
  padding: 9px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.panel-station-simple-col-justify {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.panel-station-simple-row-center {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
}

.panel-station-simple-row-justify-center {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.panel-station-simple-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.panel-station-map-select-main {
}

.panel-station-map-select-part {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding:10px;
  height:270px;
}

.panel-station-map-select-part-text {
  text-align: center;
  font-size: 16px;
  max-width:100px;
}

.panel-station-map-select-part-title {
  text-align: center;
  width:60px;
}

.panel-station-map-select-part-part {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 162px;
  height:202px;
}

.panel-station-map-select-part-part-element {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 80px;
  height:100px;
}

.panel-station-map-selector-checkbox {
  transform: scale(1.5);
}

.panel-station-map-selector-part {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100px;
  height:90px;
}

.font-current-analog-sensor {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.led-sensor {
  margin:auto;
  border: 1px solid #BDBDBD;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.led-sensor-2x {
  margin:auto;
  border: 1px solid #BDBDBD;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.dash-logo-led {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  position: relative;
}

.dash-logo-led:before {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #74BA77;
  border: 1px solid #999999;
  content: "";
  display: block;
  position: absolute;
  top: 50px;
  left: -17px;
  pointer-events: none;
}

.genericled-50-b {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #74BA77;
  border: 4px solid white;
  position: relative;
  top: -3px; left: -4px;
  flex-shrink: 0 !important;
}

.genericled-50-b:before {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: none;
  border: 2px solid #999999;
  content: "";
  display: block;
  position: absolute;
  top: -4px;
  left: -4px;
  right: 2px;
  bottom: 2px;
  pointer-events: none;
}

.blueled {
  background: #658fb8!important;
}

.greenled {
  background: #74BA77!important;
}

.redled {
  background: #F66A60!important;
}

.orangeled {
  background: #F6B960!important;
}

.greyled {
  background: #c4c2c2!important;
}

.bluedashled:before {
  background: #658fb8!important;
}

.greendashled:before {
  background: #74BA77!important;
}

.reddashled:before {
  background: #F66A60!important;
}

.orangedashled:before {
  background: #F6B960!important;
}

.greydashled:before {
  background: #afafaf!important;
}

.station_title_bar{
  display:flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.station_title_one_element {
  width:40px;
  height:40px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.station_title_one_element_size {
  width:40px;
  height:40px;
}

.station_title_one_logo_size {
  width:35px;
  object-fit: cover !important;
}

.station_title_one_logo_size_2g {
  width:45px;
  object-fit: cover !important;
}

.station_title_one_logo_move_left {
  position: relative;
  left:8px;
}

.station_title_nosignal_text {
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  position: relative;
  bottom:28px;
}

.station_title_battery_text {
  font-style: italic;
  font-weight: normal;
  font-size: 10px;
  position: relative;
  bottom:38px;
  left:7px;
}

.battery-logo {
  height: 40px;
  margin-left: 7px;
  margin-bottom: 0px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

@media screen and (min-width: 601px) {
  .station_title_text {
    padding-top:5px;
    padding-left:0px;
    flex:1;
    font-size:26px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    flex-grow: 1 !important;
  }
}

@media screen and (max-width: 600px) {
  .station_title_text {
    padding-top:8px;
    padding-left:0px;
    flex:1;
    font-size:15px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    flex-grow: 1 !important;
  }
}

.vertical-text {
  transform: rotate(270deg);
  width:20px;
  transform-origin: 15px 15px;
  display:flex;
  justify-content:center;
  align-items: center;
}

.text-bold-font {
  font-weight: bold;
}

.my-label {
  margin-bottom: 0;
}

.text-warning-font {
  color:red;
  font-size: 17px;
  padding-bottom:4px;
}

.text-small-warning-font {
  color:red;
  font-size: 10px;
  font-style: italic;
  padding-bottom:4px;
}

.text-dashboard-font {
  color:#215968;
  /*color:#467380;*/
  font-family: 'Source Sans Pro';
  font-size: 15px;
}

.text-dashboard-live-sensor-font {
  color:#215968;
  /*color:#467380;*/
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 900;
}

.text-dashboard-minmax-sensor-font {
  color:#215968;
  /*color:#467380;*/
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-style: oblique;
}

.logo-oblon-navbar-png {
  width: 90px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-oblon-bigger-navbar-png {
  width: 70px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-sensor-png {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-sensor-2-3-size-png {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-sensor-half-size-png {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-sensor-half-size-oblon-png {
  width: 50px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-sensor {
  height: 20px;
}

.icon-sensor-30 {
  width: 30px;
  height: 30px;
}

.icon-sensor-150 {
  width: 150px;
  height: 150px;
}

.main-gestion-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.main-gestion-div-flex-1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  flex:1;
}

.navbar-gestion-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.main-center-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex:1;
  font-family: 'Source Sans Pro';
  line-height:normal;
}

.main-center-column-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex:1;
}

.main-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  /*max-height: 24px;*/
}

.footer-link {
  color: white;
  font-size: 12px;
  text-decoration: none;
}

.footer-link:hover {
  color:white; 
  text-decoration:none; 
  cursor:pointer;  
}

.footer-copyright {
  font-size: 9px;
}

.footer-captina-mg {
  /*align-self: right;*/
  min-width: 300px;
  display: flex;
  justify-content: center;
}

.footer-captina {
  width:60px;
  padding-right:30px;
}

.footer-mg {
  width:60px;
  padding-right:30px;
}

.footer-logo-captina {
  width:60px;
}

.footer-logo-mg {
  width:50px;
}

.panel-contact-modal {
  margin: 20px;
  background-color: #FFF;
  border: 1px solid transparent;
  font-family: 'Source Sans Pro';
  border-radius: 15px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-color: #868f94;
  display:flex;
  flex-direction: column;
  min-width: 51%;
}

.panel-contact-main {
  display:flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0.5rem;
}

.panel-contact-right-part {
 flex:1;
}

.panel-contact-top-space {
  padding-top:45px!important;
}

.panel-contact-message-textarea {
  height : 28em;
}

.panel-contact-heading {
  color: #616161;
  font-size: 26px;
  font-weight: bold;
  height: 40px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: #E0E0E0;
}

.panel-contact-body {
  color: #616161;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 14px;
  padding:10px;
  width: 20rem;
}

.panel-contact-one-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding:10px;
}

.panel-contact-message {
  min-height: 10rem!important;
}

.panel-contact-footer {
  background: none;
  padding: 9px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

div.panel-contact-one-line:nth-child(9) > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
  display: flex;
  justify-content: center;
}

.panel-contact-message-input {
  padding: 20px;
}

.panel-parameters-body-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding : 5px;
}
.panel-parameters-body-row-item {
  width:180px;
}
.panel-parameters-body-row-content {
  width:300px;
}

.panel-waterlevel-last-update {
  min-width:50px;
}

.panel-parameters-footer {
  justify-content: space-evenly!important;
  align-items: stretch!important;
}

.panel-setup-station-main {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel-setup-station-main-bottom {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.panel-station-installer-column {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.panel-installer-station-main-bottom {
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.panel-station-installer-title {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:120px;
  font-weight: bolder;
  border: 1px solid #BDBDBD;
}

.panel-station-installer-title-explanation {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-style: italic!important;
  width:110px;
  border: 1px solid #BDBDBD;
}

.panel-station-installer-value {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #BDBDBD;
  width: 240px;
}

.panel-station-installer-line-height {
  height:30px;
  font-size: 14px;
  text-align: center;
}

.panel-station-installer-line-more-available {
  background-color: black;
  height: 3px!important;
}

.panel-station-installer-line-height-end {
  width:50px;
}

.panel-setup-station-line {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* min-width: 532px; */
  width: 532px;
  min-height: 105px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.panel-setup-station-line-help {
  display: flex;
  flex-direction: column;
  width: 532px;
  border-radius: 0.25rem /* 4px */ !important;
  color:black;
  text-align: justify;
  background-color: #E0E0E0 !important;
}

.panel-setup-station-line-content-in-help {
  margin:4px!important;
}

.panel-setup-station-line-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:72px;
}

.panel-setup-station-line-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 460px;
}

.panel-setup-station-line-content-in {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  width:460px;
  height: 34px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.panel-setup-station-line-content-name {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:110px
}

.panel-setup-station-line-content-name-half {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:53px
}

.panel-setup-station-line-content-waterlevel-live {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:80px;
}

.panel-setup-station-line-content-waterlevel-live-value {
  width:10px;
}

.panel-setup-station-line-content-waterlevel-live-days {
  width:50px
}

.panel-setup-station-line-content-waterlevel-state {
  width:50px
}

.panel-setup-station-line-content-waterlevel-warning-alarm {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:90px
}

.panel-setup-station-line-content-waterlevel-warning-alarm-input {
  width:60px
}

.panel-setup-station-line-content-waterlevel-warning-alarm-text {
  width:30px
}

.panel-setup-station-line-content-analog-add-remove {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:20px;
}

.panel-setup-station-line-content-analog-conditions {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:30px;
}

.panel-setup-station-line-content-analog-live {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:50px
}

.panel-setup-station-line-content-depth-alarm-liquid-level {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:60px;
}

.panel-setup-station-line-content-analog-warning-alarm-liquid-level {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:90px;
}

.panel-setup-station-line-content-analog-warning-alarm {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:70px
}

.panel-setup-station-line-content-analog-warning-alarm-2col {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width:123px
}

.panel-setup-station-line-content-contact-email {
  width:200px;
  line-height: 1.9!important;
}

.panel-setup-station-line-content-contact-warning-alarm-header {
  display:flex;
  justify-content: center;
  align-items: center;
  width:80px;
}

.panel-setup-station-line-content-contact-email-access {
  width:140px;
}

.panel-setup-station-line-content-contact-access {
  display:flex;
  justify-content: center;
  align-items: center;
  width:70px;
}

.panel-setup-station-line-content-contact-just-warning-alarm-header {
  width:40px;
  height: 30px;;
  display:flex;
  justify-content: center;
  align-items: center;
}

.panel-station-setup-station-name {
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 7px;
  padding-bottom: 7px;
}

.panel-station-setup-sensor-main {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  padding: 8px;
}

.panel-station-setup-sensor-title-part {
  display:flex;
  flex-direction: column;
  width:85px;
}

.panel-station-setup-sensor-title-top {
  display:flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height:65px;
}

.panel-station-setup-sensor-title-bottom {
  height:65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel-station-setup-sensor-select {
  font-family: 'Source Sans Pro';
  width:80px;
}

.panel-station-setup-sensor-content-part {
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width:320px;
}

.panel-station-setup-sensor-content-part-line {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.panel-station-setup-sensor-updownstream {
  width:90px;
  display: flex;
  justify-content: center;
}

.panel-station-setup-sensor-frequencies-line {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.panel-station-setup-sensor-frequencies-italic {
  font-style: italic;
  font-weight: normal;
  font-size: 8px;
}

.panel-station-setup-sensor-value {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.panel-station-setup-sensor-line-height {
  height:40px;
  text-align: center;
}

.panel-station-setup-90 {
  height: 21px;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.panel-station-setup-sensor-frequencies-mac {
  width:120px;
}

.panel-station-setup-sensor-frequencies-samples {
  width:60px;
}

.panel-station-setup-sensor-frequencies-frequency {
  width:100px;
}

.panel-station-setup-checkbox {
  width:25px;
}

.panel-station-setup-checkbox-for-4 {
  width:100px;
}

.panel-station-setup-modem {
  width:58px;
}

.panel-station-setup-temperaturemask {
  width:45px;
}

.panel-station-setup-temperaturemask-input {
  width:40px;
}

.panel-station-setup-freestyle {
  width:105px;
}

.panel-station-setup-freestyle-input {
  width:100px;
}

.panel-station-setup-ota {
  width:100px;
}

.panel-station-setup-sensor-reference {
  width:50px;
  display: flex;
  justify-content: center;
}

.panel-station-setup-sensor-name {
  width:120px;
}

.panel-station-setup-sensor-live {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width:60px;
  justify-content: center;
  align-items: center;
}

.panel-station-setup-sensor-calibration {
  width:100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.panel-station-setup-sensor-calibration-header {
  text-wrap: balance !important;
  text-align: justify !important;
  padding-left: 0.5rem !important;
  padding-bottom: 0.25rem !important;
}


.panel-station-setup-sensor-live-value {
  width:20px;
}

.panel-station-setup-sensor-live-jours {
  width:40px;
}


.full-width {
  width:100%;
}

.panel-station-setup-sensor-input-size{
  max-width:100px;
}

.sensor-input-text {
  min-width:35px;
  max-width:120px;
  line-height: 1.9!important;
  text-align: center;
}

.sensor-numeric-input {
  min-width:50px;
  max-width:60px;
  line-height: 1.9!important;
  text-align: center;
  width: fit-content !important;
}

.disable-direct-value-numeric-input {
  pointer-events: none;
}

.panel-setup-station-sensor-input-text {
  min-width:35px;
  max-width:120px;
  line-height: 1.9!important;
  text-align: center;
}

.panel-download-flex {
  display:flex;
  flex-direction: column;
}

.panel-download-sensor-line {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding:5px;
}

.panel-download-sensor-element {
  width:150px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.panel-download-sensor-element-name {
  width:100px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.panel-download-sensor-element-logo {
  width:50px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.panel-installation-flex {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.panel-installation-element {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:20px;
  min-width: 150px;
}

.panel-installation-element-title {
  font-size: 16px;
  font-weight: bolder;
}

.panel-installation-transmitter-table {
  font-size: 12px;
  text-align: center;
  border: 1px solid #BDBDBD;
}

.panel-installation-transmitter-table-head {
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  color: white;
  background-color: grey;
  border: 1px solid #BDBDBD;
}

.panel-installation-transmitter-table-element {
  border: 1px solid #BDBDBD;
  word-wrap: break-word;
  max-width: 500px;
}

.panel-installation-wl-table {
  font-size: 12px;
  text-align: center;
  border: 1px solid #BDBDBD;
}

.panel-installation-wl-table-head {
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  color: white;
  background-color: grey;
}

.panel-installation-wl-table-element {
  border: 1px solid #BDBDBD;
}

.btn-sensor-type {
  color: #9E9E9E;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: normal;
  font-style: bold;
  text-decoration: none;
  text-align: center;
  background: #FFFFFF;
  border: 0px solid #9E9E9E;
  border-radius: 10px;
}

.btn-sensor-time {
  width:55px;
  color: #9E9E9E;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: normal;
  font-style: bold;
  text-decoration: none;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #9E9E9E;
  border-radius: 5px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.panel-chart-main-flex {
  display:flex;
  flex-direction: row;
  flex-grow:1;
}

.panel-chart-sensor-type-logo {
  height: 30px!important;
  width: 30px!important;
}

.panel-chart-flex-sensor-type {
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.panel-chart-flex-sensor-type-each {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel-chart-flex-sensor-type-each-one-name {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width:60px
}

.panel-chart-flex-sensor-time {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding:5px;
  max-height:30px;
}

.panel-chart-flex-right-part {
  display: flex;
  flex-direction: column;
  min-height:300px;
  /*min-width:450px;*/
  flex-grow:1;
}

.panel-chart-forbidden-access {
  display:flex;
  justify-content: center;
  align-items: center;
  min-height:250px;
}

.panel-station-dashboard-sensor-main-container{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.panel-station-dashboard-sensor-main {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  padding: 5px;
}

.panel-station-dashboard-sensor-title-part {
  display:flex;
  flex-direction: column;
  width:60px;
}

.panel-station-dashboard-sensor-title-top {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height:60px;
}

.panel-station-dashboard-sensor-title-bottom {
  height:60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel-station-dashboard-sensor-content-part {
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width:170px;
}

.panel-station-dashboard-sensor-content-part-line {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding-top :5px;
  padding-bottom :5px;
}

.panel-station-dashboard-sensor-waterlevel-content-part-name-depth {
  width: 80px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.panel-station-dashboard-sensor-waterlevel-content-part-live {
  width: 20px;
}

.panel-station-dashboard-sensor-waterlevel-content-part-days {
  width:50px;
}

.panel-station-dashboard-sensor-analog-content-part-name {
  width: 80px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.panel-station-dashboard-sensor-analog-content-part-live {
  display:flex;
  flex-direction: column;
  width : 80px;
}

.panel-station-dashboard-sensor-analog-content-part-live-current {
  height:30px;
}

.panel-station-dashboard-sensor-analog-content-part-minmax {
  height:20px;
}

.solution-categories {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  padding:10px;
}

.solutions-main {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 16px;
  max-width:1250px;
  text-align: justify;
}

.solutions-1 {
  font-size: 30px;
  text-align: center;
  padding: 12px;
}

.solutions-10_40-210_240 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  padding: 12px;
}

.solutions-10_13-20_24-30_32-40_42 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width:300px;
}

.solutions-10-and-13-and-22 {
  width:280px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.solutions-30-40 {
  width:280px;
  padding-top: 10px;
  border-radius:20px;
}

.solutions-31-41 {
  width:280px;
  font-size: 25px;
  text-align: center;
  padding-top: 10px;
}

.solutions-32-42 {
  width:280px;
  text-align: center;
  padding-top: 10px;
  font-size: 12px;
  font-style: italic;
}

.solutions-logo-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.solutions-logo-text_logo {
  width:90px;
  padding-left:30px;
}

.solutions-logo-text_text {
  font-size: 16px;
  width:180px;
  padding-left:10px;
}

.solutions-100 {
  text-align: center;
  font-size: 30px;
  padding: 12px;
  padding-top: 35px;
  padding-bottom: 35px;
}

.solutions-210_214-220_224-230_234-240_244{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding-top: 10px;
  padding-left: 8px;
  padding-bottom: 10px;
}

.solutions-213_223_233_243 {
  width:280px;
  font-size: 12px;
  font-style: italic;
  text-align: center;
  padding-top: 10px;
}

.solutions-214_224_234_244 {
  padding-top: 10px;
  text-align: center;
}

.solutions-214_224_234_244-button {
  display: inline-block;
  width: 170px;
  color: #607D8B;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 26px;
}

.solutions-100-button {
  display: inline-block;
  width: 300px;
  color: #607D8B;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 26px;
}

.solutions-logo-text-solution {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  padding-top: 10px;
  align-items: center;
}

.solutions-logo-text-solution_logo {
  width:80px;
}

.solutions-logo-text-solution_text {

}

.solutions-logo-text-solution_text-up {
  font-size: 30px;
  width:210px;
  padding-left:10px;
}

.solutions-logo-text-solution_text-down {
  font-size: 40px;
  padding-left:10px;
}


.solution-plus-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solution-plus-main-column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.solution-plus-main-column-one {
  max-width: 330px;
}

.solution-category-image-ensavoirplus {
  padding-left: 25px;
  padding-right: 25px;
  width:100%;
  height:100%;
  max-width: 330px;
}

.solution-categories {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  padding:10px;
}

.solution-category {
  display:flex;
  flex-direction: column;
  padding:15px;
  max-width: 670px;
  color:white;
  font-size: 14px;
  font-weight: normal;
  text-align: justify;
}

.solution-category-header {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
}

.solution-category-header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.solution-category-logo {
  width: 90px;
  height: 90px;
}

.solution-category-header-title {
  font-family: 'Source Sans Pro';
  font-size: 36px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  color: white;
  padding:10px;
}

.solution-category-content {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /*min-height:300px;*/
  max-width:600px;
  width:100%;
}

.solution-category-content-smaller {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width:100%;
  padding:5px;
  flex:1;
}

.solution-category-content-bigger {
  display:flex;
  flex-direction: column;
  padding-left:10px;
  padding-top : 14px;
  min-width: 330px;
  width:100%;
  padding:5px;
  flex:2;
}

.solution-category-img {
 width:100%;
 border-radius:20px;
}

.solution-category-btn {
  width: 170px;
  color: #607D8B;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 26px;
}

.solution-category-btn-nossolutions {
  padding-top: 15px;
  padding-bottom: 15px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.produit-categories {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  padding:10px;
}

.produit-category {
  display:flex;
  flex-direction: column;
  padding:30px;
  max-width: 670px;
  font-family: 'Source Sans Pro';
  color: white;
}

.produit-category-line {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.produit-category-one-part {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 305px;
  padding: 5px;
  text-align: justify;
}

.produit-category-title-part {
  font-size: 34px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}

.produit-category-desc-logo-title {
  display: flex;
  flex-direction: row;;
}

.produit-category-desc-logo-title-element {
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.produit-category-desc-logo-title-element-logo {
  width:50px;
  height:50px;
}

.loader-logo {
  width:200px;
  height:200px;
}

.produit-category-desc-logo-title-element-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height:50px;
  text-align: center;
  font-size:14px;
}

.produit-category-btn {
  width: 170px;
  color: #607D8B;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 26px;
}

.produit-category-img {
  width:100%;
  border-radius:20px;
}

.apropos-main {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 13px;
  max-width:830px;
}

.apropos-1 {
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.apropos-2_3_together {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.apropos-photo-nous {
  max-width:130px;
  border-radius: 6px;
}

.apropos-2_3_together-line {
  display: flex;
  flex-direction: row;
  max-width:360px;
}

.apropos-2_3-left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding:1px;
}

.apropos-2_3-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding:5px;
}

.apropos-2_3-right-name {
  font-size: 18px;
}

.apropos-2_3-right-presentation {
  text-align: justify;
  max-width: 200px;
}

.apropos-4_5_together {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding-top:15px;
}

.apropos-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 324px;
}
.apropos-4-up {
  font-size: 30px;
  padding-bottom: 5px;
}
.apropos-4-img {
  width: 310px;
  border-radius:20px;
}

.apropos-5 {
  display: flex;
  flex-direction: column;
  max-width:500px;
}

.apropos-6 {
  display: flex;
  flex-direction: column;
}

.apropos-paragraphe {
  padding: 6px;
  text-align: justify;
}

.apropos-7_11_together {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top:15px;
  padding-bottom:24px;
}

.apropos-7_10_together {
  display: flex;
  flex-direction: column;
  width: 324px;
}

.apropos-7 {

}

.apropos-7-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.apropos-8_9_together {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.apropos-8 {
}

.apropos-9-img {
  padding-right:35px;
  padding-left:10px;
  height:70px;
}

.apropos-10 {

}

.apropos-11 {
}

.apropos-11-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

.apropos-11_15_together {
  display: flex;
  flex-direction: column;
  max-width:500px;
}

.apropos-12_13-14_15_together {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.apropos-12_14 {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
}

.apropos-13_15-img {
  width:195px;
  padding-left:5px;
  padding-right:5px;
}

.accueil-main {
  display:flex;
  flex-direction: column;
  color:white;
  justify-content: center;
  align-items: center;
}

.accueil-main-column {
  display:flex;
  flex-direction: column;
  max-width: 350px;
  /*min-height:420px;*/
  justify-content: top;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.accueil-main-row {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /*min-height: 70px;*/
  padding-bottom: 20px;
}

.min-height-20 {
  min-height: 20px;
}

.accueil-main-column-line-title-bold-big {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
}

.accueil-main-column-line-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 22px;
  /*font-weight: bolder;*/
  text-align: center;
}

.accueil-main-column-line-centered-text {
  font-size: 15px;
  font-weight: normal;
  text-align: center;
}

.accueil-main-column-line-justify-text {
  font-size: 15px;
  font-weight: normal;
  text-align: justify;
  flex:1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  width: 100%;
}

.accueil-main-column-line-minititleexplanation {
  display: flex;
  flex-direction: column;
}

.accueil-main-column-line-minititleexplanation-minititle {
  font-size: 18px;
  font-weight: bolder;
  text-align: left;
}

.accueil-main-column-line-minititleexplanation-explanation {
  font-size: 15px;
  font-weight: normal;
  text-align: left;
}

.accueil-img {
  width:300px;
  border-radius:20px;
}

.accueil-main-column-line-logotext {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  width: 100%;
}

.accueil-picto {
  width:50px;
}

.accueil-alone-picto {
  width:135px;
}

.accueil-main-column-line-logotext-text {
  padding-left: 20px;
}

.modal-main {
  position: fixed;
  inset: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1)) /* #4b5563 */;
  --tw-bg-opacity: 0.7;
  justify-content: center;
  align-items: center;
  z-index: 50;
  display:flex;
  justify-content: center;
  align-items: center;
  height:100%;
  width: 100%;
  color:#616161;
}

.modal-container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1)) /* #ffffff */;
  border-radius: 0.5rem /* 8px */;
}

.modal-header {
  display:flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  margin-bottom: 1rem;
  border-top-left-radius: 0.5rem /* 8px */ !important;
  border-top-right-radius: 0.5rem /* 8px */ !important;
  font-size: 1.625rem;
  font-weight: bold;
  line-height: 1.75rem /* 28px */ !important;
  --tw-bg-opacity: 1 !important;
  /* background-color: rgb(148 163 184 / var(--tw-bg-opacity, 1)) !important; */
  background-color: #E0E0E0;
  color: #616161;
}

.modal-body {
  display:flex;
  flex-direction: column;
  padding-right: 1rem!important;
  padding-left: 1rem!important;
  overflow-y: scroll;
  max-height: 80vh !important;
  font-size: 14px;
  font-weight: normal;
  font-style: bold;
}

.modal-separator {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: space-evenly!important;
  margin-top: 2rem;
  margin-bottom: 1rem;
  height: 2.5rem;
}

.modal-show-tree {
  min-width:800px!important;
  max-width:1500px!important;
}

.leaflet-container {
  width: 800px;
  height: 600px;
}

.modal-show-transmitter-table {
  font-size: 12px;
  text-align: center;
  border: 1px solid #BDBDBD;
}

.modal-show-transmitter-table-head {
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  color: white;
  background-color: grey;
  border: 1px solid #BDBDBD;
}

.modal-show-transmitter-table-element-ok {
  background-color: green;
}

.modal-show-transmitter-table-element-ko {
  background-color: red;
}

.modal-show-transmitter-table-element-wn {
  background-color: orange;
}

.modal-show-transmitter-table-element-yl {
  background-color: yellow;
}

.modal-show-one-sensor-test {
  width:400px;
  /*
  min-width:400px!important;
  max-width:400px!important;
  */
}

.modal-one-sensor-test-table {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  border: 1px solid #BDBDBD;
}

.modal-one-sensor-test-table-textarea {
  width:400px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  margin-bottom: .5rem;
}

.table-text-table {
  font-size: 14px;
  text-align: center;
  border: 1px solid #BDBDBD;
}

.table-text-thead {
  font-weight: normal;
  font-style: italic;
  background-color: grey;
}

.table-text-tbody {
  border-bottom: 1px solid #BDBDBD;
}

.table-text-th {
  border-bottom: 1px solid #BDBDBD;
  border-right: 1px solid #BDBDBD;
  padding: 2px 4px;
}

.table-text-td {
  border-bottom: 1px solid #BDBDBD;
  border-right: 1px solid #bdbdbd62;
  padding: 2px 4px;
}

.table-text-value {

    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
  .table-text-value-tr {
    border: 1px solid black;
}

.company-list-table-text-thead {
  font-weight: normal;
  font-size: 16px;
  color: #467380
}

.company-list-table-text-td-th {
  border-bottom: 1px solid #BDBDBD;
  padding: 2px 4px;
}

.background-dark-grey {
  background-color: rgb(75, 75, 75)!important;
}

.status-ok {
  background-color: green!important;
}

.status-ko {
  background-color: red!important;
}

.status-wn {
  background-color: orange!important;
}

.status-yl {
  background-color: yellow!important;
}

.td-test {
  width:20px;
}

.span-test {
  width:100%;
  height:100%;
  background-color: orange;
}

.text-table-main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@media screen and (max-width: 1449px) {
  .map-container {
    border-radius: 15px;
    height:680px;
    width:570px;
  }
}

@media screen and (min-width: 1450px) {
  .map-container {
    border-radius: 15px;
    height:680px;
  }
}

.main-visualize-stations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex:1;
  line-height:normal;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

.main-visualize-stations-element {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.oblon-border {
  text-align: center;
  border: 1px solid #BDBDBD;
  border-radius: 26px;
  padding: 0.5rem;
  padding-left: 1rem!important;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

/*
div,table,td,tr,a,button {
  border: 1px solid black;
}
*/
